import React from "react";
import { graphql } from 'gatsby'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import readingTime from "reading-time";

import Layout from "../components/layout"
import SEO from "../components/seo"
import SidebarRecentPosts from "../components/sidebar-recent-posts";
import RichText from "../components/rich-text"

const paragraph = (content) => {
	var para = []
	content.map((p,i) => {
    if (p.nodeType === "paragraph")	para.push(p)
    return null
  })
	return para
};

const link = (content, meta) => {

  content.map((v, i) => {
    v.content.map((v1, i1) => {
      if (v1.nodeType === "hyperlink") {
				//console.log ("Node Type ...: ", i1, v1)
        if (v1.content[0].value && v1.content[0].value.substr(0, 1) === ">") {
          meta.map((m,i2) => {
            if (m.url === v1.data.uri) {
              v1.content[0].data = m
            }
            return null
          })
        }
      }
      return null
    });
    return null
  });
};

const singlePost = ({data, classes , pageContext}) => {
  if (data.post.content && data.post.content.json && data.post.content.fields && data.post.content.fields.linksMetadata) 
  {
    link(paragraph(data.post.content.json.content),data.post.content.fields.linksMetadata)
  }
  
  return (
    <Layout page='single-post'>
    <SEO 
      title={data.post.title} 
      description={documentToPlainTextString(data.post.shortDescription ? data.post.shortDescription.json : '')} 
      image={data.post.cover? data.post.cover.fluid.src:''}
    />
      <div className="container container-flex">
        <main role="main">
          <img
            src={data.post.cover? data.post.cover.fluid.src:''}
            alt={data.post.coverAltText || 'no image description'}
            className="post-image flashing"
          />
          <h6>{readingTime(documentToPlainTextString(data.post.content ? data.post.content.json : '')).text}</h6>
          <h2>{data.post.title}</h2>

          <RichText text={data.post.content ? data.post.content.json : ''} linksMetadata={data.post.content.fields ? data.post.content.fields.linksMetadata : []}/>

        </main>

        <aside className="sidebar">
          <SidebarRecentPosts />
        </aside>
      </div>
    </Layout>
  )
}

export default singlePost;

export const singlePostQuery = graphql`
query singlePostQuery ($slug: String!){
  post: contentfulSimpleLife(slug: {eq: $slug}) {
    title
    comment
    content {
      json
      fields {
          linksMetadata {
            url
            title
            description
            site_name
            image
          }
      }
    }
    shortDescription {
      json
    }
    cover {
      fluid {
        aspectRatio
        sizes
        src
        srcSet
      }
    }
  }
}
`